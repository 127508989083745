import "../../styles/globals.css";
import { SessionProvider } from "next-auth/react";
import { QueryClient, QueryClientProvider } from "react-query";
import Script from "next/script";
import { DFPSlotsProvider } from 'react-dfp';
const queryClient = new QueryClient();

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}) {
  // const [isTransitioning, setIsTransitioning] = useState(false);
  //This initialises the hotjar script
  // useEffect(() => {
  //   hotjar.initialize(3148949, 6);
  // }, []);

  const getLayout = Component.getLayout || ((page) => page);
  //Pattern described here: https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/

  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-R0L5MHGTNF" strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-R0L5MHGTNF');`}
      </Script>
      <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <DFPSlotsProvider dfpNetworkId="146418060">
          {getLayout(<Component {...pageProps} />)}
        </DFPSlotsProvider>
      </QueryClientProvider>
      </SessionProvider>
      </>
      );
}
